import React from "react";


import GlobalContext from '../GlobalContext';
import Swal from 'sweetalert2';

import '../css/cpabuild.css';

class CPALocker extends React.Component {
  
  static contextType = GlobalContext;
  
  constructor(props) {
	super(props)
	this.state = {
		easyOffers: ["1196166", "238118", "53174771", "53167680", "53170604", "53168090"],
		offers: []
	}		
  }

  async componentDidMount() {
	  try {
		  const info = await this.context.utils.getUserInfo();
		  this.getOffers();
	  } catch(e) {
		this.getOffers();
	  }
  }

  getOffers() {
	const { username, info } = this.context;
	fetch(`${global.baseApiURL}/offers?username=${username}&pioneer=${info ? info.pioneerReferrer : "none"}`).then(res => res.json()).then(offers => {
		this.setState({offers: offers})
	})
  }
  
  goToOffer(url) {
	  
	Swal.fire({
		title: 'IMPORTANT',
		icon: 'warning',
		html: `After you complete this offer, <b>return</b> to <u>BuxArmy.com</u> to claim your R$!`,
		allowEscapeKey: false,
		allowOutsideClick: false,
		confirmButtonText: `<i class="fa fa-thumbs-up"></i> I understand!`,
		confirmButtonAriaLabel: 'Thumbs up, great!',

	})
	const btn = Swal.getConfirmButton()
	btn.outerHTML = `<a id="confirmBtn" target="_blank" style="color: #fff" href="${url}"> ${btn.outerHTML}</a>`
	document.getElementById('confirmBtn').onclick = () => {
		Swal.close()
		localStorage.setItem("offerClicked", Date.now())
	}
  }
  

  render() {
	
	const { username, info, utils } = this.context;
	const { offers, easyOffers } = this.state;
	const goToOffer = this.goToOffer.bind(this);
	const pioneer = localStorage.getItem("pioneer");
	let easyOffer = true;
	
	function getOfferBadge(offer) {
		let showBadge = false;
		
		
		if (easyOffers.indexOf(offer.id) >= 0 && easyOffer) {
			easyOffer = false;
			showBadge = true;
			
		}
		return showBadge ? <span className="badge badge-secondary easyBadge">Easy</span> : <></>
	}
	
    return (
      <>
       <div id="my-locker" style={{display: "block"}}>
			<div id="custom-heading" className="custom-colorcode">
				<div id="custom-heading-text">Complete one of the offers to receive R$</div>
			</div>
			<div id="my-locker-top">
				<span>Earn Points</span>
			</div>

			<div id="my-locker-body">

				<div id="my-locker-body-human-verification" style={{display: "none"}}>
					<button><i className="fa fa-spinner fa-spin"></i>Verify Through Survey</button>
				</div>

				<div id="my-locker-body-offers">
					<div className="my-locker-body-text-top"></div>
					<div id="my-locker-body-offers-list" align="center">
						{
							offers.map((offer, i) => {
								
								return (<a key={i} onClick={() => { goToOffer(offer.url) }} className="notranslate offerURL" target="_blank">
										<div className="mobile-image"><img src={offer.network_icon}></img></div>
										<div className="mobile-description">
											<span>{offer.anchor}</span>
											<font>{offer.conversion}</font>
											{getOfferBadge(offer)}
										</div>
										<div className="mobile-button" align="center"><button>+{pioneer ? (offer.robux * 2).toLocaleString() : offer.robux.toLocaleString()} R$</button></div>
								</a>)
							})
						}
					
					</div>
					<div className="my-locker-body-text-bottom"></div>
				</div>

			</div>

			<div id="my-locker-bottom">
				<span>It takes 2-3 minutes for the prize to unlock in your Country.</span>
			</div>

		</div>
      </>
    );
  }
}

export default CPALocker;
