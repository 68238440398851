import React from 'react';

import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";




import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './css/style.css';


import Withdraw from './views/Withdraw.js';
import Referral from './views/Referral.js';
import Home from './views/Home.js';
import Earn from './views/Earn.js';
import Profile from './views/Profile.js';

import GlobalContext from './GlobalContext'
import Swal from 'sweetalert2'

import routes from './routes.js';
import hooks from './hooks.js';
import $ from 'jquery';

import PioneerAnalytics from './PioneerAnalytics';

global.PioneerAnalytics = PioneerAnalytics;
global.baseApiURL = "https://api.robloxarmy.com";

const popupCountries = ["US", "GB", "CA", "AU"];
function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}



function getWindowsOS(){
	// http://msdn.microsoft.com/en-us/library/ms537503(v=vs.85).aspx#PltToken
	if(navigator.appVersion.indexOf("Windows NT 10.")!=-1){
		return 'Windows 10';
	}else if(navigator.appVersion.indexOf("Windows NT 6.3")!=-1){
		return "Windows 8.1";
	}else if(navigator.appVersion.indexOf("Windows NT 6.2")!=-1){
		return "Windows 8";
	}else if(navigator.appVersion.indexOf("Windows NT 6.1")!=-1){
		return "Windows 7";
	}else if(navigator.appVersion.indexOf("Windows NT 6.0")!=-1){
		return "Windows Vista";
	}else if(navigator.appVersion.indexOf("Windows NT 5.2")!=-1){
		return "Windows Server 2003; Windows XP x64 Edition";
	}else if(navigator.appVersion.indexOf("Windows NT 5.1")!=-1){
		return "Windows XP";
	}else if(navigator.appVersion.indexOf("Windows NT 5.01")!=-1){
		return "Windows 2000, Service Pack 1 (SP1)";
	}else if(navigator.appVersion.indexOf("Windows NT 5.0")!=-1){
		return "Windows 2000";
	}else if(navigator.appVersion.indexOf("Windows NT 4.0")!=-1){
		return "Windows NT 4.0";
	}else if(navigator.appVersion.indexOf("Windows 98; Win 9x 4.90")!=-1){
		return "Windows Millennium Edition (Windows Me)";
	}else if(navigator.appVersion.indexOf("Windows 98")!=-1){
		return "Windows 98";
	}else if(navigator.appVersion.indexOf("Windows 95")!=-1){
		return "Windows 95";
	}else if(navigator.appVersion.indexOf("Windows CE")!=-1){
		return "Windows CE";
	}else{
		return "Windows OS, Version nicht bekannt";
	}
}


class App extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			countryCode: localStorage.getItem("country") || "",
			username: localStorage.getItem("username"),
			commissions: [],
			funds: 0,
			utils: {
				getUserCommissions: this.getUserCommissions.bind(this),
				getUserInfo: this.getUserInfo.bind(this),
				withdraw: this.withdraw.bind(this),
				getFunds: this.getFunds.bind(this),
				redeemCode: this.redeemCode.bind(this),
				logout: (forced) => {
					global.ReactGA.event({
					  category: 'Home',
					  action: forced ? "Force Logout" : 'Logout'
					});
					localStorage.setItem("username", "")
					window.location.reload();
				}
				
				
			},
			info: {}
		}
		
		this.queriesFetch = [];
		
		global.thing = this.state;
		
		if (this.state.username) {
			this.getUserInfo().then(info => {
				if (info.success == false) this.state.utils.logout(true);
			});
		}
	
		
	}
	
	addPopups() {
		/*const hooks = {
			"robloxfun.xyz": "//awaitbackseatprod.com/aa/a7/24/aaa724e2db9903ad8f4aabc0405a3368.js",
			"funblox.xyz": "//awaitbackseatprod.com/24/cd/a9/24cda9cce984789352f510023737b70b.js",
			"roblox.cool": "//awaitbackseatprod.com/f1/21/0d/f1210dabb21fdeddb6a60d74b9348f87.js",
			"freebux.vip": "//awaitbackseatprod.com/8d/b7/88/8db788ff347e7d1183642d2387c9207a.js"
		}
		const script = document.createElement("script");

		script.src = hooks[window.location.host] || "//awaitbackseatprod.com/ca/30/45/ca304523582b637b70e5b62a17bf8190.js";
		script.async = true;

		document.body.appendChild(script);*/
	}
	
	componentDidMount() {
		const pioneer = getQueryVariable("pioneer");

		if (pioneer) {
			localStorage.setItem("pioneer", pioneer)
		}
		
		
		if (!this.state.countryCode) {
			this.getCountry()
		} else {
			if (popupCountries.indexOf(this.state.countryCode) >= 1) this.addPopups();
		}
		
		const currentReferrer = localStorage.getItem("referrer");
		if (currentReferrer) return;
		
		const referrer = getQueryVariable("ref");
		if (referrer) {
			localStorage.setItem("referrer", referrer.replace(/\//g, ""));
		} else {
			localStorage.setItem("referrer", "razormarl")
		}
		
		
	}
	
	getCountry() {	

		$.ajax({
			url: 'https://freegeoip.app/json/',
			type: 'GET',
			success: (data) => {
			   this.setState({countryCode: data["country_code"]});
			   localStorage.setItem("country", data["country_code"])
			   if (popupCountries.indexOf(this.state.countryCode) >= 1) this.addPopups();
			},
			error: function(data) {
				console.log(data)
				//resolve('NA');
			}
		});

	}
	
	getUserInfo(override) {
		return new Promise((resolve, reject) => {
			if (this.state.info && this.state.info.sub && !override) {
				resolve(this.state.info);
				return;
			}
			
			if (this.pending) {
				this.queriesFetch.push(resolve);
				return;
			}

			const { username } = this.state;
			this.pending = true;
			fetch(`${global.baseApiURL}/user/${username}`).then(res => res.json()).then(data => {
				this.queriesFetch.forEach(otherResolve => {
					otherResolve(data);
				})
				this.queriesFetch = [];
				
				this.setState({info: data});
				this.pending = false;
				resolve(data);
			})
		})
	}
	
	getUserCommissions() {
		const { username } = this.state;
		fetch(`${global.baseApiURL}/commissions/${username}`).then(res => res.json()).then(data => {
			this.setState({commissions: data})
		})
	}
	
	getFunds() {
		fetch(`${global.baseApiURL}/funds`).then(res => res.json()).then(num => this.setState({funds: num}));
	}
	
	redeemCode(code) {
		const { username } = this.state;
		fetch(`${global.baseApiURL}/redeem/${code}?username=${username}`, {
			method: 'get',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			}
		}).then(res => {
			return res.json()
		}).then(data => {
			if (data.hrefBtn) {
				Swal.fire({
				  icon: data.type,
				  title: data.type == "error" ? 'Oops...' : "Success!",
				  text: data.msg,
				  confirmButtonText: data.text
				}).then(() => {
					window.open(data.url);
				})
			} else {
				Swal.fire({
				  icon: data.type,
				  title: data.type == "error" ? 'Oops...' : "Success!",
				  text: data.msg,
				})
				this.getUserInfo(true);
			}
			
		})
	}
	
	withdraw(gameId, amount) {
		const { username } = this.state;
		var os;
		if(navigator.appVersion.indexOf("Windows ")!=-1){
			os = getWindowsOS();
		}else{
			os = navigator.platform;
		}
		fetch(`${global.baseApiURL}/withdraw`, {
			method: 'post',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username,
				gameId, 
				amount,
				os
			})
		}).then(res => {
			return res.json()
		}).then(data => {
			Swal.fire({
			  icon: data.type,
			  title: data.type == "error" ? 'Oops...' : "Success!",
			  html: data.msg,
			}).then(() => {
				if (!data.noRedirect) window.location.href = data.customRedirect || "/withdraw"
			})
		})
	}
	
	render() {
		
		const { username } = this.state;
	
		return (

			<GlobalContext.Provider value={this.state}>
				<Router>
					<Switch>
						{
							routes.map(route => {
								return (
									<Route exact path={route.path} key={route.path}>
										{username ? <route.content /> : <Home />}
									</Route>
								)
							})
						}
						

						
					</Switch>
				</Router>
			</GlobalContext.Provider>
			  
			);

		}
}

export default App;
