import React from "react"


import GlobalContext from '../GlobalContext';

import GameLocker from '../components/GameLocker.js';
import CPALocker from '../components/CPALocker.js';
import OGLocker from '../components/OGLocker.js';


import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import MediaQuery from 'react-responsive'
import earn from '../css/earn.css';

class Earn extends React.Component {
	static contextType = GlobalContext;
	
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: "cpabuild",
			tabs: [{
				content: <>EASY <i className="fas fa-dollar-sign"></i></>,
				id: "cpabuild",
			}, {
				content: <>FAST <i className="fas fa-dice"></i></>,
				id: "adgate"
			}, {
				content: <>NEW <i class="fas fa-gem"></i></>,
				id: "offertoro"
			}]
		}
		/*
		{
			content: <>HOT <i className="fas fa-bolt"></i></>,
			id: "ogads"
		},*/
		

	}
	
	componentDidMount() {
		const pioneer = localStorage.getItem("pioneer");
		let offerClicked = localStorage.getItem("offerClicked");
		if (offerClicked) offerClicked = parseInt(offerClicked);
		console.log(offerClicked)
		
		this.context.utils.getUserInfo().then(info => {
			
			var config = {
				SE: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 3
				},
				SA: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				AE: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				CA: {
					offers: ["cpabuild", "offertoro"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				GB: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				DE: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				US: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 2
				},
				CH: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				DK: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				ES: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				NO: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				GR: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				NL: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 1
				},
				LU: {
					offers: ["cpabuild"],
					defaultTab: "cpabuild",
					amountToUnlockAll: 2
				}
			}
			
			
			var tabHooks = {
				cpabuild: <>EASY <i className="fas fa-dollar-sign"></i></>,
				adgate: <>HOT <i class="fas fa-fire-alt"></i></>,
				//ogads: <>FAST <i className="fas fa-dice"></i></>,
				offertoro: <>NEW <i class="fas fa-gem"></i></>,
				
			}
			
			if (info.cpaOffers && info.cpaOffers.length > 0) {
				this.setState({selectedTab: "adgate"});
			}
			
			if (!info || info.offersCompleted == 0) {
				this.setState({
					tabs: [{
						content: <>EASY <i className="fas fa-dollar-sign"></i></>,
						id: "cpabuild"
					}, {
						content: <>FAST <i className="fas fa-dice"></i></>,
						id: "adgate"
					}]
				})
			}
			
			if (config[this.context.countryCode]) {
				const configSettings = config[this.context.countryCode];
				const tabs = [];
				
				for (const id of (info.offersCompleted >= configSettings.amountToUnlockAll ? Object.keys(tabHooks) : configSettings.offers)) {
					console.log(id)
					tabs.push({
						content: tabHooks[id],
						id
					});
				}
				console.log(tabs)
				this.setState({
					selectedTab: configSettings.defaultTab,
					tabs
				})
			}
			
			if (info && info.pioneerReferrer == "discordref" && this.context.countryCode !== "SE") {
				this.setState({
					selectedTab: "adgate",
					tabs: [{
						content: <>EASY <i className="fas fa-dollar-sign"></i></>,
						id: "cpabuild",
					}, {
						content: <>FAST <i className="fas fa-dice"></i></>,
						id: "adgate"
					}, {
						content: <>NEW <i class="fas fa-gem"></i></>,
						id: "offertoro"
					}]
					/*{
						content: <>HOT <i className="fas fa-bolt"></i></>,
						id: "ogads"
					},*/
				})
			}
			
			
			

			

			
			if (info.offersCompleted > 0 && info.balance > 5) {
				let lastWithdrawPopup = localStorage.getItem("lastWithdrawPopup");
				if (!isNaN(lastWithdrawPopup)) lastWithdrawPopup = parseInt(lastWithdrawPopup);
				if ((Date.now() - lastWithdrawPopup) < 60000 * 20 && !pioneer) {
					return;
				}
				global.PioneerAnalytics.poll(info.username, "receiveWithdrawPopup");
				Swal.fire({
				  title: 'Congrats!',
				  text: `You earned ${info.balance} R$! Would you like to withdraw it?`,
				  icon: 'success',
				  showCancelButton: true,
				  confirmButtonColor: '#3085d6',
				  cancelButtonColor: '#d33',
				  confirmButtonText: 'Withdraw'
				}).then((result) => {
				   if (result.isConfirmed) window.location.href = "/withdraw";
				   localStorage.setItem("lastWithdrawPopup", Date.now())
				})
			}
			
		})
		
		window.addEventListener("message", (event) => {
			
			const data = event.data;
			if (!data.url) return;
			Swal.fire({
				title: 'IMPORTANT',
				icon: 'warning',
				html: `After you complete this offer, <b>return</b> to <u>BuxArmy.com</u> to claim your R$!`,
				allowEscapeKey: false,
				allowOutsideClick: false,
				confirmButtonText: `<i class="fa fa-thumbs-up"></i> I understand!`,
				confirmButtonAriaLabel: 'Thumbs up, great!',

			})
			const btn = Swal.getConfirmButton()
			btn.outerHTML = `<a id="confirmBtn" target="_blank" style="color: #fff" href="${event.origin + data.url}"> ${btn.outerHTML}</a>`
			document.getElementById('confirmBtn').onclick = () => {
				Swal.close()
				localStorage.setItem("offerClicked", Date.now())
			}
		}, false);
		

		

	}
	
	changeTab(evt) {
		const targetEl = evt.target.tagName == "BUTTON" ? evt.target : evt.target.parentElement;
		const tab = targetEl.attributes.getNamedItem("data-tab").value;
		
		global.ReactGA.event({
		  category: 'Earn',
		  action: 'Change Button',
		  label: tab
		});
		
		this.setState({selectedTab: tab});
	}
	
	getTabContent() {
		const { tabs, selectedTab } = this.state;
		const { username, info } = this.context;
		
		
		const btnSelected = tabs.find(tab => tab.id == selectedTab);
		
		switch (btnSelected.id) {
							/*<CPALocker></CPALocker>*/
			/*<iframe className="afiframe mt-2" frameBorder="0" id="offwallIF" src={`https://d1xkyo9j4r7vnn.cloudfront.net/public/i_fr?it=1062010&key=bbac8&s1=${info.pioneerReferrer}s2=${username}&cpaOffers=${JSON.stringify(info.cpaOffers)}&offersCompleted=${info.offersCompleted}`}></iframe>*/
			/*
			<CPALocker></CPALocker>*/
			case "cpabuild": 
				var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
				var cpaBuildAccount = this.context.countryCode == "US" ? "it=1062010&key=bbac8" : "it=1199467&key=76a83"
				return (<>
					<iframe className="afiframe mt-2" frameBorder="0" id="offwallIF" src={`https://d1xkyo9j4r7vnn.cloudfront.net/public/i_fr?${cpaBuildAccount}&s1=${info.pioneerReferrer}&s2=${username}&cpaOffers=${JSON.stringify(info.cpaOffers)}&offersCompleted=${info.offersCompleted}`}></iframe>
				</>)
			break;
			case "ogads":
				return <OGLocker></OGLocker>
			break;
			case "adgate":
				return <iframe className="afiframe mt-2" frameBorder="0" id="offwallIF" src={`https://wall.adgaterewards.com/n6iVrg/${username}`}></iframe>
			break;
			case "offertoro":
				return <iframe className="afiframe mt-2" frameBorder="0" id="offwallIF" src={`https://www.offertoro.com/ifr/show/25342/${username}/10695`}></iframe>
			break;
			case "gamelocker":
				return <GameLocker></GameLocker>
			break;
		}
	}

	joinDiscord() {
		const { username } = this.context;
		window.open(`${global.baseApiURL}/joinDiscord?username=${username}`, '_blank')
	}
	
	joinInstagram() {
		const { username } = this.context;
		window.open(`https://www.instagram.com/buxarmy_com/`, '_blank')
	}
	
	render() {
		const desktop = this.desktop.bind(this);
		const mobile = this.mobile.bind(this);
		return (
			<div>
				<MediaQuery minDeviceWidth={1100}>
					{desktop()}
				</MediaQuery>
				
				<MediaQuery maxDeviceWidth={1100}>
					{mobile()}
				</MediaQuery>
			</div>
		)
		
		
	}
	
	desktop() {
		const { tabs, selectedTab } = this.state;
		
		const joinInstagram = this.joinInstagram.bind(this);
		const joinDiscord = this.joinDiscord.bind(this)
		
		const changeTab = this.changeTab.bind(this);

		return (
			<>
			<Header></Header>
			<section className="landing_container__18jTX noPadding">
				<div className="col-md-9">
					<div className="card-body">
						<h2 className="mt-0 header-title">COMPLETE THE OFFERS FOR R$</h2>
						{
							tabs.length > 2 ?
								<><button onClick={joinDiscord} type="button" disabled="" id="sf1r" value="0" className="mt-1 btn btn-sm btn-primary waves-effect waves-light"><i className="fab fa-discord"></i> Join for 0.25R$</button>
								<button onClick={joinInstagram} type="button" disabled="" id="sf1r" value="2" className="mt-1 btn btn-sm btn-danger waves-effect waves-light insta"><i className="fab fa-instagram"></i> Follow for 0.25R$</button></>
							: <></>
						}

						<div className="tab-content">
							<div className="row">
								{
									tabs.length >= 1 ? 
										<div className="col-xl-3">
											<div className="row pb-2">

												{
													tabs.map(tab => {
														return (
															<div key={tab.id} className="col-8 col-sm-8 col-centered col-md-12 pt-2 px-1">
																<button id="offwallUp" data-tab={tab.id} onClick={changeTab} value="0" type="button" style={{"fontSize": "30px"}} className={`btn btn-lg btn-block btn-primary ${tab.id == selectedTab ? "btn-danger" : "btn-primary"} waves-effect waves-light`}> {tab.content}</button>
															</div>
														)
													})
												}
											</div>
										</div>
										: <></>
								}
								
								<div className="col-xl-9 pl-1 pr-0">
									{this.getTabContent()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</>)
	}
	
	
	
	mobile() {
		const { tabs, selectedTab } = this.state;
		
		const joinInstagram = this.joinInstagram.bind(this);
		const joinDiscord = this.joinDiscord.bind(this);
		const changeTab = this.changeTab.bind(this);
		const btns = [];
		if (tabs.length > 3) {
			for (var i=0; i < tabs.length; i += 2) btns.push(tabs.slice(i,i+2));
		} else {
			btns.push(tabs);
		}
		console.log(btns)
		return (
			<>
			<Header></Header>
			<section className="landing_container__18jTX noPadding">
				<div className="container">
					{
						tabs.length > 1 ?
							<>
							{
								btns.map(row => {
									return (
										<div className="row justify-content-center resize">
											{
												row.map(tab => {
													return (
														<button key={tab.id} style={btns.length > 1 ? {width: "40%"} : {}} onClick={changeTab} data-tab={tab.id} className={`btn waves-effect waves-light ${tabs.length > 2 ? "adBtn" : "adBtn2"} ${tab.id == selectedTab ? "btn-danger" : "btn-primary"}`}>{tab.content}</button>
														
													)
												})
											}			
											
										</div>
									)
								})
							}
							{tabs.length > 2 ?
								<><div className="row justify-content-center resize">
									<button onClick={joinDiscord} className="btn btn-primary socialBtn"><i className="fab fa-discord"></i> Discord +0.25</button>
									<button onClick={joinInstagram} className="btn socialBtn insta"><i className="fab fa-instagram"></i> Instagram +0.25</button>
							</div></> : <></>}</>
						: <></>
					}
					<div className="row justify-content-center">
						{this.getTabContent()}
					</div>
				</div>
			</section>
			<Footer></Footer>
		</>)
	}
}

export default Earn;