import React from "react";

import GlobalContext from '../GlobalContext.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import withdraw from '../css/withdraw.css';
import Swal from 'sweetalert2'

let code = ""
function Promocodes() {
	const context = React.useContext(GlobalContext);
	const { info, funds } = context;

	
	function redeem() {
		const pioneer = localStorage.getItem("pioneer");
		if (pioneer) {
			Swal.fire({
			  icon: "success",
			  title: "Success!",
			  text: `Promocode succesfully redeemed!`
			})
			return;
		}

		context.utils.redeemCode(code);
	}
	

	function inputChange(evt) {
		code = evt.target.value.toUpperCase();
	}
	
	return ( 
		<div id="root">
			<Header></Header>
			<section className="landing_container__18jTX noPadding">
				<div className="landing_content__Ozl7S landing_heading__K9vZ3">
					<div className="col-md-9">
						<div className="row row d-flex justify-content-md-center ">
							<div className="col-12 col-lg-5 h-100 h-100">
								<h3 className="withdrawTitle">Redeem Promocode</h3>
								<p style={{fontSize: "1.2rem"}}>Enter the code you would like to redeem below, then press the button</p>
								<div className="input-group mt-auto" style={{minHeight: "42px", marginBottom: "0.7rem"}}>
									<div className="input-group-prepend">
										<div className="input-group-text"><span className="robux-icon"></span></div>
									</div><input onChange={inputChange} autocomplete="off" type="text" className="form-control col-form-label-lg" id="payout" name="payout" aria-describedby="exampleRobux" placeholder="Enter your code..." style={{height: "42px"}}></input>
								</div>
								<div onClick={redeem} className="btn signup-btn btn-primary btn-block"><i class="fas fa-gift" style={{marginRight: "2px"}}></i>REDEEM</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			<Footer></Footer>
		</div>
	)
}



export default Promocodes;