import Giveaways from './views/Giveaways.js';
import Promocodes from './views/Promocodes.js';
import Withdraw from './views/Withdraw.js';
import Referral from './views/Referral.js';
import Home from './views/Home.js';
import Earn from './views/Earn.js';
import Profile from './views/Profile.js';

const routes = [{
	path: "/giveaways",
	content: Giveaways
},{
	path: "/promocodes",
	content: Promocodes
},{
	path: "/withdraw",
	content: Withdraw
},
{
	path: "/referrals",
	content: Referral
},
{
	path: "/earn",
	content: Earn
},
{
	path: "/profile",
	content: Profile
},
{
	path: "/",
	content: Home
}]

export default routes;