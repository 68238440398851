import React from "react";


import GlobalContext from '../GlobalContext';
import NavLink from './NavLink';
import '../css/nav.css';

class Header extends React.Component {
  
  static contextType = GlobalContext;
  
  constructor() {
	  super();
	  this.navHidden = true;
  }

  toggleNav() {
	this.navHidden = !this.navHidden;
	this.navHidden ? document.querySelector('.nav-pages').style.display = "none" : document.querySelector('.nav-pages').style.display = "block";
  }  
  
  goToWithdraw() {
	  window.location.href = "/withdraw";
  }
  
  render() {
	
	const { username, info, utils } = this.context;
	const goToWithdraw = this.goToWithdraw.bind(this)
	const toggleNav = this.toggleNav.bind(this);
	
	const pioneer = localStorage.getItem("pioneer");

    return (
      <>
        <div className="nav-wrapper bg-primary">
			<h1 className="mainTitle"  style={{display: username ? "none" : "block"}}>{pioneer || window.location.host !== "buxarmy.com" ? window.location.host : "BuxArmy.com"}</h1>
			
			<button className="navbar-toggler" type="button" onClick={toggleNav} style={{display: username ? "block" : "none"}}>
				<span></span>
				<span></span>
				<span></span>
			</button>

			<div className="nav-right right" style={{display: username ? "block" : "none"}}>
				<div className="nav-pages">

									
					<NavLink to="/" >Home</NavLink>
				
					<NavLink to="/earn" >Earn</NavLink>
					<NavLink to="/withdraw" >Withdraw</NavLink>
					<NavLink to="/referrals" >Referrals</NavLink>
					<NavLink to="/promocodes">Promocodes</NavLink>
					<NavLink to="/profile" style={window.innerWidth >= 1100 ? {marginLeft: "41px"} : {}} >Profile</NavLink>
					<a onClick={utils.logout} className="logout nav-page m16 p">Logout</a>
				</div>
				<a href="../login" id="" className="nav-link c p button-blue m14b h">Link your account</a>
				
				
				<div id="loggedMenu" style={{display: username ? "block" : "none"}}>
					<div className="nav-mobile-username nav-link c m14b" style={{background: "none", fontSize: "11px"}}>{window.innerWidth < 360 ? username.substr(0, 7) : username}</div>
					<div onClick={goToWithdraw} className="nav-link c m14b" >
						<span id="balanceBox" className="m16b">{info.balance}</span> R$
					</div>
					<div className="nav-info" style={{marginLeft: "-30px !important"}}>
						<div className="nav-ititle m16b">{username}</div>
						<a onClick={utils.logout} className="nav-itext p">Logout</a>
					</div>
					
				</div>
			   
				
			</div>
		</div>
      </>
    );
  }
}

export default Header;
