import React from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import GlobalContext from '../GlobalContext';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js'

import '../css/style.css';

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

class Home extends React.Component {
	static contextType = GlobalContext;
	
	constructor() {
		super();
		
		this.state = {
			modalShown: false
		}
	}
	
	componentDidMount() {
		
		var s = document.createElement('script');
		s.src = 'https://www.googletagmanager.com/gtag/js?id=AW-686519878';
		s.onload = function() {
			  window.dataLayer = window.dataLayer || [];
			  function gtag(){window.dataLayer.push(arguments);}
			  gtag('js', new Date());

			  gtag('config', 'AW-686519878');
			  gtag('event', 'conversion', {'send_to': 'AW-686519878/mXhtCNfniPEBEMbsrccC'});
		};
		(document.head || document.documentElement).appendChild(s);

	
		this.resize();
		window.onresize = this.resize.bind(this);
		
		const pioneer = localStorage.getItem("pioneer");
		if (pioneer) {
			return;
		}
		this.showModal();
	}
	
	resize() {
		const width = 1920;
		const ratio = window.innerWidth / width;
		if (document.getElementsByClassName("landingPageTitle") && document.getElementsByClassName("landingPageTitle")[0] && ratio < 1 && window.innerWidth >= 700) {
			document.getElementsByClassName("landingPageTitle")[0].style.zoom = (ratio * 120) + "%"
		}
	}
	
	showModal() {

		if (this.context.username) {
			window.location.href = "/earn";
			return;
		}
		global.ReactGA.event({
		  category: 'Home',
		  action: 'Start Now'
		});
		Swal.fire({
		  title: 'RBLX USERNAME',
		  input: 'text',
		  inputAttributes: {
			autocapitalize: 'off',
			placeholder: "Enter your RBLX username..."
		  },
		  allowOutsideClick: false,
		  allowEscapeKey: false,
		  showCancelButton: false,
		  confirmButtonText: 'Next',
		  showLoaderOnConfirm: true,
		  preConfirm: (login) => {
			return fetch(`https://api.robloxarmy.com/profile?username=${login}`)
			  .then(async response => {
				const result = await response.json();
				console.log(result)
				if (!response.ok || !result.Id) {
					global.ReactGA.event({
						category: 'Home',
						action: 'Profile Not Found!',
						label: login
					});
					throw new Error(response.statusText)
				}
				return result;
			  })
			  .catch(error => {
				Swal.showValidationMessage(
				  `Please enter a real RBLOX account! Try Again!`
				)
			  })
		  },
		  allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
		  if (result.value) {
			Swal.fire({
				  title: `Welcome, ${result.value.Username}!`,
				  imageUrl: `https://www.roblox.com/headshot-thumbnail/image?userId=${result.value.Id}&width=100&height=100&format=png`,
				  confirmButtonText: 'GET R$!',
				  allowOutsideClick: false,
				  allowEscapeKey: false,
				  showCancelButton: false,
				  showLoaderOnConfirm: true,
				  
				  preConfirm: () => {
					global.ReactGA.event({
					  category: 'Home',
					  action: 'Link Account'
					});
					return fetch(`${global.baseApiURL}/login`, {
						method: 'post',
						headers: {
						  'Accept': 'application/json',
						  'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							username: result.value.Username,
							referrer: parseInt(getQueryVariable("r")) || -1,
							pioneerReferrer: localStorage.getItem("referrer")
						})
					}).then(async res => {
						const result = await res.json();
						console.log(result)
						return result;
					})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then(data => {
				console.log(data)
				localStorage.setItem("username", result.value.Username);
				window.location.href = "/earn";
			})
		  }
		})
	}
	
	render() {
		const showModal = this.showModal.bind(this);
		
		const { username } = this.context;
		const { modalShown } = this.state;
		return ( 
			<div id="bg">
				<Header></Header>
				
				
				<section className="resize landing_container__18jTX con">
					<div className="col-lg-11">
						<div className="row">
							<div className="col">
								<div className="landingPageTitle">
									<h1>Start Earning</h1>
									<h1 className="blueText">FREE R$</h1>
									<button onClick={showModal} className="startNow"><i className="fas fa-user-plus"></i>Start Now</button>
								</div>
							</div>
							<div className="col textRight">
								<div className="landingYouTube">
									<iframe width="650" height="350" src="https://www.youtube.com/embed/yxGD5yggMqg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								</div>
								<div className="socials">
									<a className="icon facebook" href="https://www.facebook.com/Robloxarmy-103889784777424" target="_blank" rel="noopener noreferrer">
										<i className="fab fa-facebook-square"></i>
									</a>
									<a className="icon twitter" href="https://twitter.com/robloxarmy8" target="_blank" rel="noopener noreferrer">
										<i className="fab fa-twitter-square"></i>
									</a>
									<a className="icon discord" href="https://discord.gg/vKNyNcNW6s" target="_blank" rel="noopener noreferrer">
										<i className="fab fa-discord"></i>
									</a>
								</div>

							</div>
						</div>
					</div>
				</section>
				
				<Footer></Footer>
			</div>
		)
	}
}

export default Home;