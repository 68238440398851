import React from "react";


import GlobalContext from '../GlobalContext';
import Swal from 'sweetalert2';

import '../css/cpabuild.css';

class GameLocker extends React.Component {
  
  static contextType = GlobalContext;
  
  constructor(props) {
	super(props)
	this.state = {
		easyOffers: ["1196166", "238118", "53174771", "53167680", "53170604", "53168090"],
		offers: []
	}		
  }

  componentDidMount() {
	  this.getOffers();
  }

  getOffers() {
	this.setState({offers: [{
		name: "Lifting Legends Simulator",
		description: "Play this game for 10 minutes and earn 2 Robux!",
		img: "https://i.imgur.com/9mxaiEJ.png",
		link: "https://www.roblox.com/games/6320688051/NEW-CODE-Lifting-Legends-Simulator",
		id: "game1",
		robux: 2,
		
	},{
		name: "Battle Royale Simulator",
		description: "Play this game for 10 minutes and earn 2 Robux!",
		img: "https://i.imgur.com/NcTu3Vu.png",
		link: "https://www.roblox.com/games/6551386772/Battle-Royale-Simulator",
		id: "game2",
		robux: 2
	},{
		name: "2 Player Super Villain Tycoon",
		description: "Play this game for 10 minutes and earn 2 Robux!",
		img: "https://i.imgur.com/VVS26bx.png",
		link: "https://www.roblox.com/games/4744261043/2-Player-Super-Villain-Tycoon",
		id: "game3",
		robux: 2
	}]})
  }
  
  goToOffer(offer) {
	  
	const { info } = this.context;
	console.log(info)
	fetch(`${global.baseApiURL}/claimGame?game=${offer.id}&username=${info.username}`).then(res => res.text()).then(data => {
		if (data) {
			Swal.fire({
				title: 'Error',
				icon: 'error',
				html: data,
				allowEscapeKey: false,
				allowOutsideClick: false,
				confirmButtonText: `<i class="fa fa-thumbs-up"></i> Take me back!`,
				confirmButtonAriaLabel: 'Thumbs up, great!',

			})
		} else {
			Swal.fire({
				title: 'IMPORTANT',
				icon: 'warning',
				html: `After you complete this offer, <b>return</b> to <u>BuxArmy.com</u> to claim your R$!`,
				allowEscapeKey: false,
				allowOutsideClick: false,
				confirmButtonText: `<i class="fa fa-thumbs-up"></i> I understand!`,
				confirmButtonAriaLabel: 'Thumbs up, great!',

			})
			const btn = Swal.getConfirmButton()
			btn.outerHTML = `<a id="confirmBtn" target="_blank" style="color: #fff" href="${offer.link}"> ${btn.outerHTML}</a>`;
			document.getElementById('confirmBtn').onclick = () => {
				Swal.close()
				localStorage.setItem("offerClicked", Date.now())
			}
			
		}
	})
	
	
	
  }
  

  render() {
	
	const { username, info, utils } = this.context;
	const { offers, easyOffers } = this.state;
	const goToOffer = this.goToOffer.bind(this);
	const pioneer = localStorage.getItem("pioneer");
	let easyOffer = true;
	
	function getOfferBadge(offer) {
		/*let showBadge = false;
		
		
		if (easyOffers.indexOf(offer.offerid) >= 0 && easyOffer) {
			easyOffer = false;
			showBadge = true;
			
		}*/
		var showBadge = true;
		return showBadge ? <span class="badge badge-secondary easyBadge">Easy</span> : <></>
	}
	console.log(offers)
    return (
      <>
       <div id="my-locker" style={{display: "block", background: "#e6f3ff"}}>
			<div id="custom-heading" class="custom-colorcode">
				<div id="custom-heading-text" style={{background: "#ba67c4"}}>Complete one of the offers to receive R$</div>
			</div>
			<div id="my-locker-top">
				<span>Earn Points</span>
			</div>

			<div id="my-locker-body">

				<div id="my-locker-body-human-verification" style={{display: "none"}}>
					<button><i class="fa fa-spinner fa-spin"></i>Verify Through Survey</button>
				</div>

				<div id="my-locker-body-offers">
					<div class="my-locker-body-text-top"></div>
					<div id="my-locker-body-offers-list" align="center">
						{
							offers.map(offer => {
								
								return (<a onClick={() => { goToOffer(offer) }} class="notranslate offerURL" target="_blank">
										<div class="mobile-image"><img src={offer.img}></img></div>
										<div class="mobile-description">
											<span>{offer.name}</span>
											<font>{offer.description}</font>
											{getOfferBadge(offer)}
										</div>
										<div class="mobile-button" align="center"><button>+{pioneer ? (offer.robux * 2).toLocaleString() : offer.robux.toLocaleString()} R$</button></div>
								</a>)
							})
						}
					
					</div>
					<div class="my-locker-body-text-bottom"></div>
				</div>

			</div>

			<div id="my-locker-bottom">
				<span>It takes 2-3 minutes for the prize to unlock in your Country.</span>
			</div>

		</div>
      </>
    );
  }
}

export default GameLocker;
