import React from "react";

import GlobalContext from '../GlobalContext.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import withdraw from '../css/withdraw.css';
import Swal from 'sweetalert2'

//import WithdrawDesktop from './WithdrawDESKTOP';
import MediaQuery from 'react-responsive'
import $ from 'jquery';
import names from './usernames';

let amount = 0;
let nameIndex = Math.floor(Math.random() * names.length)
let chosenNames = [];

let index = 0;
for (var i = nameIndex; i < nameIndex+5;i++) {
	if (i < names.length - 1) index = i;
	else index = i - (names.length - 1)
	
	chosenNames.push(names[index])
}

let lastWithdraw = 0;

global.swalTest = Swal;

class Withdraw extends React.Component {
	static contextType = GlobalContext;
	
	constructor() {
		super();
		
		this.state = {
			games: [],
			currentGame: 0
		}
	}
	
	componentDidMount() {
		if (this.context.funds == 0) this.context.utils.getFunds();
	}
	
	cashOut() {
		const pioneer = localStorage.getItem("pioneer");
		const { info } = this.context;
		if (pioneer) {
			Swal.fire({
				icon: "success",
				title: "Withdrawl Success!",
				html: `Your ${amount} robux has been added to your account!<br>To see proof go to <a target="_blank" href="https://roblox.com/transactions">roblox.com/transactions</a>`
			})
			return;
		}
		
		
		if (Date.now() - lastWithdraw < 1000) return;
		if (info.balance > 5) global.PioneerAnalytics.poll(info.username, "withdrawAttempt");
		else global.PioneerAnalytics.poll(info.username, "withdrawAttemptNoBalance");
		this.context.utils.withdraw(this.state.currentGame, amount);
		lastWithdraw = Date.now();
	}
	
	getUserGames() {
		return new Promise((resolve, reject) => {
			const { info } = this.context;
			fetch(`${global.baseApiURL}/getUserGames?id=${info.id}`)
			  .then(async response => {
				const result = await response.json();
				this.setState({games: result});
				resolve(result);
			  })
			  .catch(error => {
				
			  })
		})
	}
	
	setCurrentGame() {
		this.setState({currentGame: $("#games").val()});
	}
	
	mobile(info, cashOut, pointChange) {
		const context = this.context;
		const getUserGames = this.getUserGames.bind(this);
		const setCurrentGame = this.setCurrentGame.bind(this);
		function showVideo() {
			$("#step1").fadeOut(200);
			setTimeout(() => {
				$("#step2").fadeIn();
			}, 200)
			
		}
		
		function showFinal() {
			$("#step2").fadeOut(200);
			getUserGames().then(() => {
				setTimeout(() => {
					$("#step3").fadeIn();
				}, 200);
			})		
			
		}
		
		function back() {
			$("#step3").fadeOut(200);
			setTimeout(() => {
				$("#step2").fadeIn();
			}, 200)
		}
		
		
		return ( 
			<div id="root">
					<Header></Header>
					<section className="landing_container__18jTX noPadding">
						<div className="landing_content__Ozl7S landing_heading__K9vZ3">
							<div className="col-md-9">
								<div className="row d-flex justify-content-md-center ">
									<div id="step1" className="col-12 col-lg-5 h-100">
										<h3 className="withdrawTitle">Withdraw R$</h3>
										<p style={{fontSize: "1.2rem"}}>Enter the amount of R$ below then press the withdraw button</p>
										<div className="input-group mt-auto" style={{minHeight: "42px", marginBottom: "0.7rem"}}>
											<div className="input-group-prepend">
												<div className="input-group-text"><i class="fas fa-arrow-alt-circle-down"></i></div>
											</div><input onChange={pointChange} defaultValue={info.balance} autocomplete="off" type="text" className="form-control col-form-label-lg" id="payout" name="payout" aria-describedby="exampleRobux" placeholder="Robux" style={{height: "42px"}}></input>
										</div>
										<div onClick={showVideo} className="btn signup-btn btn-primary btn-block">Next</div>
									</div>
									
									<div id="step2" class="col-lg-5" style={{display: "none"}}>
										<h3 class="withdrawTitle">Withdraw R$</h3>
										<p style={{fontSize: "1.2rem"}}>Please watch the video below to withdraw your robux</p><iframe src="https://www.youtube.com/embed/5vUgTAZnOyU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
										<div onClick={showFinal} class="btn signup-btn btn-primary btn-block">Next</div>
									</div>
									
									<div id="step3" class="col-12 col-lg-5 h-100" style={{display: "none"}}>
										<h3 class="withdrawTitle">Withdraw R$</h3>
										<p style={{fontSize: "1.2rem"}}>Please choose the roblox game you would like to withdraw to.</p>
										<div class="input-group mt-auto" style={{minHeight: "42px", marginBottom: "0.7rem"}}>
											<select class="form-control" id="games" onChange={setCurrentGame}>
												<option selected disabled>Select Game</option>
												{
													this.state.games.map(({id, name}) => {
														return <option value={id}>{name}</option>
													})
												}
												
												
											</select>
										</div>
										<div onClick={cashOut} class="btn signup-btn btn-primary btn-block mb-2"><svg class="svg-inline--fa fa-arrow-circle-down fa-w-16 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{width: "1em", height: "1em"}}>
												<path fill="currentColor" d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"></path>
											</svg>Withdraw</div>
										<div onClick={back} class="btn signup-btn btn-danger btn-block mb-2">Back to video</div>
										<p>Need help? Join our discord server! <a href="https://discord.gg/buxarmy">discord.gg/buxarmy</a></p>
									</div>
								</div>
							</div>
						</div>

					</section>
					<Footer></Footer>
				</div>
			)
	}
	
	desktop(info, cashOut, pointChange) {
		const context = this.context;
		const getUserGames = this.getUserGames.bind(this);
		const setCurrentGame = this.setCurrentGame.bind(this);
		function showNextStep() {
			$("#step1").fadeOut(200);
			getUserGames().then(() => {
				$("#step2").fadeIn();
			})
			
		}
		
	
		return (
			<>
				 <div id="root">
					<Header></Header>
					<section class="landing_container__18jTX noPadding">
						<div class="landing_content__Ozl7S landing_heading__K9vZ3">
							<h1>BUXARMY.COM</h1>
							<h2>Enter an amount to withdraw from your balance below!</h2>
							<div class="col-md-9">
								<div class="row col-12">
									<div class="col-md-4">
										<div class="mini-stat clearfix bg-success p-4 imstat1 imstats">
											<span class="mini-stat-icon"><img height="50" src="assets/images/ROBUX_ICON.png"></img></span>
											<div class="mini-stat-info text-right text-white">
												<span class="counter text-white">{context.funds.toLocaleString()}</span>
												R$ STOCK
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="mini-stat clearfix bg-warning p-4 imstat2 imstats">
											<span class="mini-stat-icon"><i class="mdi mdi-checkbox-multiple-blank-outline text-white"></i></span>
											<div class="mini-stat-info text-right text-white">
												<span class="counter text-white">{(Math.floor(Date.now() / 1000 / 6) - 264245636).toLocaleString()}</span>
												COMPLETED OFFERS
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="mini-stat clearfix bg-danger p-4 imstat3 imstats">
											<span class="mini-stat-icon"><i class="mdi mdi-wifi text-white"></i></span>
											<div class="mini-stat-info text-right text-white">
												<span class="counter text-white">{(Math.floor(Date.now() / 1000 / 6) - 259245636).toLocaleString()}</span>
												REGISTERED USERS
											</div>
										</div>
									</div>
								</div>


								<div class="row d-flex justify-content-md-center ">
									<div id ="step1" class="col-12 col-md-5 col-lg-5 h-100">
										<h3>Withdraw R$</h3>
										<p style={{fontSize: "1.2rem"}}>Enter the amount of R$ below then press the withdraw button</p>
											<div class="input-group mt-auto" style={{minHeight: "42px", marginBottom: "0.7rem"}}>
											<div class="input-group-prepend">
												<div class="input-group-text"><i class="fas fa-arrow-alt-circle-down"></i></div>
											</div>
											<input onChange={pointChange} defaultValue={info.balance} autocomplete="off" style={{height: "42px"}} type="text" class="form-control col-form-label-lg" id="payout" name="payout" aria-describedby="exampleRobux" placeholder="Robux"></input>
										</div>
										<div onClick={showNextStep} class="btn signup-btn btn-primary btn-block" style={{minwWidth: "40px"}}>
											<svg class="svg-inline--fa fa-arrow-circle-down fa-w-16 mr-2" style={{width: "1em", height: "1em"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
													<path fill="currentColor" d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"></path>
											</svg>
											Withdraw
										</div>
									</div>
									<div id="step2" class="col-12 col-md-5 col-lg-5 h-100" style={{display: "none"}}>
										<h3 class="withdrawTitle">Withdraw R$</h3>
										<p style={{fontSize: "1.2rem"}}>Please choose the roblox game you would like to withdraw to.</p>
										<div class="input-group mt-auto" style={{minHeight: "42px", marginBottom: "0.7rem"}}>
											<select class="form-control" id="games" onChange={setCurrentGame}>
												<option selected disabled>Select Game</option>
												{
													this.state.games.map(({id, name}) => {
														return <option value={id}>{name}</option>
													})
												}
												
												
											</select>
										</div>
										<div onClick={cashOut} class="btn signup-btn btn-primary btn-block mb-2"><svg class="svg-inline--fa fa-arrow-circle-down fa-w-16 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{width: "1em", height: "1em"}}>
												<path fill="currentColor" d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"></path>
											</svg>Withdraw</div>
										<p>Need help? Join our discord server! <a href="https://discord.gg/buxarmy">discord.gg/buxarmy</a></p>
									</div>
									<div class="col-md-5 ">
										<h3 class="card-title">Watch this video to withdraw</h3>
										<iframe width="370" height="220" src="https://www.youtube.com/embed/5vUgTAZnOyU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
									</div>					
								</div>
								
								

							</div>
							
							

							
							
							
						</div>

					</section>
					<Footer></Footer>
				</div>
			</>	
		)
	}
	
	render() {
		const { info } = this.context;
		
		const cashOut = this.cashOut.bind(this);
		const desktop = this.desktop.bind(this);
		const mobile = this.mobile.bind(this);
		
		if (amount == 0 && info.balance) amount = info.balance;
		function pointChange(evt) {
			amount = parseInt(evt.target.value)
		}
		return (
			<>
			
				<MediaQuery minDeviceWidth={950}>
					{desktop(info, cashOut, pointChange)}
				</MediaQuery>
				
				<MediaQuery maxDeviceWidth={950}>
					{mobile(info, cashOut, pointChange)}
				</MediaQuery>
					
				
			</>	
		)
	}
}
	


/*
function mobile(info, cashOut, pointChange) {
	
}


function desktop(info, cashOut, pointChange, context) {
	
	
}*/

export default Withdraw;