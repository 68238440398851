import React from "react";

import GlobalContext from '../GlobalContext.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import giveaways from '../css/giveaways.css';
import Swal from 'sweetalert2'

let code = ""
function Giveaways() {
	const context = React.useContext(GlobalContext);
	const { info, funds } = context;
	
	return ( 
		<div id="root">
			<Header></Header>
			<section className="displayNormal">
				<div className="giveawayTimerHolder">
					<div className="giveawayTimer">
						<div className="column spacer">
							<div className="columns">
								<div className="column">
									<div className="timerBox">00</div>
									<p>Hours</p>
								</div>
								<div className="column">
									<div className="timerBox">00</div>
									<p>Minutes</p>
								</div>
								<div className="column">
									<div className="timerBox">00</div>
									<p>Seconds</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="giveawayContentHolder">
					<div className="giveawayContent">
						<div className="giveawayTitle">Giveaway</div>
						<div className="giveawayInnerContent">
							<div className="boxItem first">
								<div>Giveaway Prize</div>
							</div>
							<div className="itemBox">
								<div>1,000 Robux</div>
							</div>
							<div className="boxItem floatNone">
								<div>Users Joined</div>
							</div>
							<div className="itemBox last">
								<div>539</div>
							</div>
							<div className="giveawayButtonHolder">
								<div className="giveawayButton">Join Giveaway</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	)
}



export default Giveaways;