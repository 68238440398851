import React from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext from '../GlobalContext';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import referral from '../css/referral.css';

class Referral extends React.Component {
	
	static contextType = GlobalContext;
	
	constructor(props) {
		super(props);
	}
	
	componentDidMount() {
		this.context.utils.getUserCommissions();
	}
	
	copy() {
		 var textField = document.createElement('textarea')
		textField.innerText = this.referralUrl
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy')
		textField.remove();
		
		toast('Copied to clipboard!', {
			position: "top-center",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
		});
	}
	
	render() {
		const copy = this.copy.bind(this);
		const { info, commissions } = this.context;
		console.log(commissions)
		this.referralUrl = `https://robloxarmy.com?r=${info.sub}`;
		return (
			<>
				<Header></Header>
				<ToastContainer
					position="top-center"
					autoClose={1500}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover={false}
				/>
				<section className="landing_container__18jTX noPadding">
					<div className="landing_content__Ozl7S landing_heading__K9vZ3">
						<h1>BUXARMY.COM</h1>
						<h2>Earn easy R$</h2>
						<p>When someone connects their account with your referral link you get 5% of all their earnings!!</p>
						<div className="container">
							<div className="col-sm-12 mt-0 p-2">
								<div className="row">
									<div className="col-md-12 col-lg-4 mb-4">
										<div className="card">
											<h5 className="card-header primary-color text-white text-center py-4">
												<strong>
													Users
												</strong>
											</h5>
											<div className="card-body text-center px-lg-5">
												<div className="col-md-12">
													<h4>
														{commissions.length}
													</h4>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 col-lg-8 mb-4">
										<div className="card">
											<h5 className="card-header primary-color text-white text-center py-4">
												<strong>
													Share
												</strong>
											</h5>
											<div className="card-body row px-lg-5">
												<div className="col-md-12">
													<div className="input-group mr-sm-2">
														<input type="text" disabled className="form-control py-0 referralBox" id="ref" value={this.referralUrl} readOnly=""></input>
														<div className="input-group-append">
															<button onClick={copy} className="input-group-text material-tooltip-main">
																<i className="fa fa-copy"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 mb-4">
										<div className="card">
											<h5 className="card-header primary-color text-white text-center py-4">
												<strong>
													Referrals
												</strong>
											</h5>
											<div className="card-body px-lg-5">
												<table className="table text-center">
													<thead>
														<tr>
															<th scope="col">
																Username
															</th>
															<th scope="col">
																Commission
															</th>
														</tr>
													</thead>
													<tbody>
														{commissions.map(({username, commissionEarned}) => {
															return (
															<tr>
																<td>{username}</td>
																<td>{commissionEarned}</td>
															</tr>)
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer></Footer>
				
			</>
		)
	}
}

export default Referral