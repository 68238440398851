import React from 'react'

function Footer() {
	return (
		<>
			<section className="landing_container__18jTX faq">
				<div className="landing_content__Ozl7S">
					<h2>Frequently Asked Questions</h2>
					<p>Earn FREE R$ by completing surveys, participating in giveaways & referring friends!</p>
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-lg-4 m-15px-tb mb-4 d-flex align-items-stretch text-center">
								<div className="p-4">
									<div className="text-left">
										<p className="title font-weight-bold text-uppercase spacing mt-4 mx-4">
											<i className="fas fa-square mr-2" aria-hidden="true"></i>
											<strong>
												How to link your account?
											</strong>
										</p>
										<p className="font-weight-bolder mx-4">When the site loads, you will be shown a popup which you can enter your username.</p>
										<br></br>
										<p className="font-weight-bolder mx-4 note">*NOTE: We will NEVER ask for your password*</p>
									</div>
								</div>
							</div>
							<div className="col-md-12 col-lg-4 m-15px-tb mb-4 d-flex align-items-stretch text-center">
								<div className="p-4">
									<div className="text-left">
										<p className="title font-weight-bold text-uppercase spacing mt-4 mx-4">
											<i className="fas fa-square mr-2" aria-hidden="true"></i>
											<strong>
												DOES YOUR SITE WORK? WHERE'S THE PROOF?
											</strong>
										</p>
										<p className="font-weight-bolder mx-4">Of course our site works! Don't believe us? Feel free to join our <a href="https://discord.gg/" target="_blank">Discord server</a> and find out!</p>
									</div>
								</div>
							</div>
							<div className="col-md-12 col-lg-4 m-15px-tb mb-4 d-flex align-items-stretch text-center">
								<div className="p-4">
									<div className="text-left">
										<p className="title font-weight-bold text-uppercase spacing mt-4 mx-4">
											<i className="fas fa-square mr-2" aria-hidden="true"></i>
											<strong>
												Still don't believe us?
											</strong>
										</p>
										<p className="font-weight-bolder mx-4">Every use starts off with 1 R$ for free! You can withdraw that too your account to verify that our payouts are real!</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="footer text-center py-3">
				<div className="container-fluid">
					DISCLAIMER: WE ARE NOT AFFILIATED OR ENDORESED BY ROBLOX CORPORATION
					© buxarmy.com 2019-{new Date().getFullYear()}
					
					
				</div>
			</div>
		</>
	)
}

export default Footer;