import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function NavLink(props) {
	var location = useLocation();
	
	var isActive = location.pathname === props.to;
	var className = "nav-page m16 p "
	className += isActive ? 'active' : '';

	/*return (
		<li className=">
			<Link className={className} {...props}>
				{props.children}
			</Link>
		</li>
	)*/
	return(
		<Link className={className} {...props}>
			{props.children}
		</Link>
	);
    
}



export default NavLink;