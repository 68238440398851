class PioneerAnalytics {
	
	static poll(username, dimension) {
		fetch(`${global.baseApiURL}/poll`, {
			method: 'post',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username,
				dimension
			})
		})	
	}
}

export default PioneerAnalytics;