import React from 'react';

import GlobalContext from '../GlobalContext.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';


function Profile() {
	const context = React.useContext(GlobalContext);
	const { info } = context;
	return (
		<>
			<Header></Header>
			<section className="landing_container__18jTX noPadding">
				<div className="landing_content__Ozl7S landing_heading__K9vZ3">
					<h1>BUXARMY.COM</h1>
					<h2>This is your profile where you can see your earnings!</h2>
					<p>All your contenst points & offers completed are listed blow!</p>
					<div className="container">
						<section className="p-5 z-depth-1">
							<h3 className="text-center text-uppercase font-weight-bold mb-5"></h3>
							<div className="row d-flex justify-content-center">
								<div className="col-md-6 col-lg-3 mb-4 text-center">
									<h4 className="h1 font-weight-normal mb-3">
										<i className="fas fa-donate text-primary"></i>
										<span className="d-inline-block count-up">{info.totalEarned}</span>
									</h4>
									<p className="font-weight-normal text-muted">Total Earned</p>
								</div>
							
								<div className="col-md-6 col-lg-3 mb-4 text-center">
									<h4 className="h1 font-weight-normal mb-3">
										<i className="fas fa-gamepad text-primary"></i>
										<span className="d-inline-block count2">{info.offersCompleted}</span>
									</h4>
									<p className="font-weight-normal text-muted">Offers Done</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</>)
}

export default Profile;