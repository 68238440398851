import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

global.ReactGA = require('react-ga');

global.ReactGA.initialize('UA-125786454-21');
global.ReactGA.pageview(window.location.pathname + window.location.search);
global.ReactGA.event({
  category: 'impression',
  action: window.location.pathname + window.location.search
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

